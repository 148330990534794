export const NODE_ENV = process.env.NODE_ENV || 'production';

export type AppInfo = {
  name: string;
  version: string;
  nodeEnv: string;
  appEnv: string;
  release: string;
};

export const APP_INFO = (process.env.APP_INFO as unknown as AppInfo) || {
  name: '',
  version: '',
  nodeEnv: '',
  appEnv: '',
  release: '',
};
