import { APP_INFO } from './client/constants/environment';

import('./client')
  .then(({ renderAppFor }) => {
    // @ts-ignore
    window.APP_INFO = APP_INFO;

    renderAppFor(APP_INFO);
  })
  .catch(() => {
    const unsupportedElement = document.getElementById('unsupported');

    if (unsupportedElement) {
      unsupportedElement.style.visibility = 'visible';
    }
  });
